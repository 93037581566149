@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@100;200;300;400;500;600;700&family=PT+Sans:wght@700&display=swap');

body {
  font-family: 'IBM Plex Sans KR', sans-serif !important;
}

:root {
  --vh: 100%;
}

html,
body, #main {
  min-height: var(--vh);
  height: 100%;
}

#image-grid-wrapper img:hover {
  border: 4px solid purple;
}

#image-grid-wrapper img {
  cursor: pointer;
}

#sortable-wrapper {
  display: contents;
}
.handle {
  cursor: pointer;
}
.sortable-ghost {
  background-color: rgba(0,0,255,0.1);
}